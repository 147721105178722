import React from 'react';
import { string } from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { useI18N } from 'relient/i18n';

import s from './footer.less';

const result = ({ className }) => {
  useStyles(s);
  const i18n = useI18N();

  return (
    <div className={classNames(className, s.Root)}>
      <div className={s.Container}>
        <div>
          <div className={s.ContactTitle}>{i18n('contact')}</div>
          <div className={s.Contacts}>
            <div className={s.Phone}>
              <div>{i18n('phone')}:&nbsp;</div>
              <div>
                <div>{i18n('ChinaPhone')}</div>
                <div>{i18n('USAPhone')}</div>
              </div>
            </div>
            <div>{i18n('email')}: <a href="mailto:contact@waijule.com">contact@waijule.com</a></div>
            <div>{i18n('USAAddress')}</div>
            <div>{i18n('ChinaAddress')}</div>
          </div>
        </div>
        <div className={s.QRCodes}>
          <div className={s.QRCode}>
            <div className={s.Title}>{i18n('followWechatAccount')}</div>
            <img className={s.QRCodeImage} src="/images/wechat-qr-code.png" alt="Wechat QR Code" />
            <div>{i18n('followWechatAccountDescription')}</div>
          </div>
          <div className={s.QRCode}>
            <div className={s.Title}>{i18n('downloadApp')}</div>
            <img className={s.QRCodeImage} src="/images/app-qr-code.png" alt="App QR Code" />
            <div>{i18n('downloadAppDescription')}</div>
          </div>
        </div>
      </div>
      <div className={s.CopyRight}>{i18n('copyRight')}</div>
    </div>
  );
};

result.propTypes = {
  className: string,
};

result.displayName = __filename;

export default result;
