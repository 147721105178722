import React from 'react';
import { string, node, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import relientAdminStyles from 'relient-admin/styles.css';
import classNames from 'classnames';
import globalStyle from './global_.less';
import s from './layout.less';
import Footer from './footer';
import Header from './header';
import selector from './layout-selector';

const result = ({
  children,
  className,
}) => {
  useStyles(globalStyle, relientAdminStyles, s);
  const { language } = useSelector(selector);

  return (
    <div>
      <Header language={language} />

      <div className={classNames(s.Content, className)}>
        {children}
      </div>

      <Footer />
    </div>
  );
};

result.propTypes = {
  children: node,
  className: string,
  title: node,
  subTitle: string,
  multipleCard: bool,
};

result.displayName = __filename;

export default result;
