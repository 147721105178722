import React, { useState } from 'react';
import { array, string } from 'prop-types';
import Cookies from 'js-cookie';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Drawer } from 'antd';
import { features, HOME } from 'shared/constants/features';
import { map, flow, reject, propEq } from 'lodash/fp';
import { useI18N } from 'relient/i18n';
import { EN, CN, LANGUAGE_COOKIE } from 'shared/constants/language';
import EarthIcon from 'shared/components/icons/earth';
import ListIcon from 'shared/components/icons/list';
import ArrowIcon from 'shared/components/icons/arrow';
import classNames from 'classnames';

import s from './header.less';

const setENLanguage = () => {
  if (Cookies.get(LANGUAGE_COOKIE) !== EN) {
    Cookies.set(LANGUAGE_COOKIE, EN);
    global.location.reload();
  }
};

const setCNLanguage = () => {
  if (Cookies.get(LANGUAGE_COOKIE) !== CN) {
    Cookies.set(LANGUAGE_COOKIE, CN);
    global.location.reload();
  }
};

const result = ({
  language,
}) => {
  useStyles(s);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const i18n = useI18N();
  const menu = (
    <>
      <div className={s.Features}>
        {flow(
          reject(propEq('key', HOME)),
          map(({ text, externalLink, key }) => (
            <a key={key} href={externalLink || `#${key}`}>{i18n(text)}</a>
          )),
        )(features)}
      </div>
      <div className={s.Languages}>
        <div
          className={classNames({ [s.active]: language === EN }, s.Language)}
          onClick={setENLanguage}
        >
          EN
        </div>
        /
        <div
          className={classNames({ [s.active]: language === CN }, s.Language)}
          onClick={setCNLanguage}
        >
          中
        </div>
        <EarthIcon />
      </div>
    </>
  );

  return (
    <div className={s.Root}>
      <h1 className={s.Logo}>
        <img alt="WJL HomeServices" src="/images/logo.svg" />
        WJL HomeServices
      </h1>
      <div className={s.MobileMenuTrigger} onClick={() => setIsMenuVisible(true)}>
        <ListIcon />
      </div>
      <div className={s.DesktopMenu}>
        {menu}
      </div>
      <Drawer
        visible={isMenuVisible}
        onClose={() => setIsMenuVisible(false)}
        closeIcon={<ArrowIcon color="white" />}
        className={s.MobileMenu}
      >
        {menu}
      </Drawer>
    </div>
  );
};

result.propTypes = {
  selectedFeatureKeys: array,
  language: string.isRequired,
};

result.displayName = __filename;

export default result;
