import React from 'react';
import { number, string } from 'prop-types';

const result = ({ className, size = 24, color = 'black' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.75 12h16.5M3.75 6h16.5M3.75 18h16.5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

result.propTypes = {
  className: string,
  size: number,
  color: string,
};

result.displayName = __filename;

export default result;
