// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n._1nqbZ {\n  background: #00468e;\n  font-size: 12px;\n  color: white;\n}\n._1nqbZ a {\n  color: white;\n}\n@media (min-width: 600px) {\n  ._3Bj0N {\n    width: 1080px;\n    margin: 0 auto;\n    display: flex;\n    padding-top: 45px;\n  }\n}\n@media (max-width: 600px) {\n  ._3Bj0N {\n    padding: 30px 60px;\n  }\n}\n._3XZea {\n  margin-bottom: 16px;\n}\n._3m-_B {\n  line-height: 2;\n}\n@media (max-width: 600px) {\n  ._3m-_B {\n    margin-bottom: 24px;\n  }\n}\n._3p0RI {\n  display: flex;\n}\n@media (min-width: 600px) {\n  ._3p0RI {\n    margin-left: 200px;\n  }\n}\n@media (max-width: 600px) {\n  ._3p0RI {\n    justify-content: space-between;\n  }\n}\n._3p0RI ._2d8_0 {\n  font-size: 16px;\n}\n._3p0RI ._1n2nv {\n  width: 120px;\n  text-align: center;\n}\n@media (min-width: 600px) {\n  ._3p0RI ._1n2nv {\n    margin-right: 44px;\n  }\n}\n._3p0RI ._1FMFN {\n  width: 100px;\n  display: block;\n  margin: 8px auto 16px;\n}\n.ULvOJ {\n  display: flex;\n}\n._3FnQS {\n  text-align: center;\n  font-weight: lighter;\n  padding: 22px 0 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"Root": "_1nqbZ",
	"Container": "_3Bj0N",
	"ContactTitle": "_3XZea",
	"Contacts": "_3m-_B",
	"QRCodes": "_3p0RI",
	"Title": "_2d8_0",
	"QRCode": "_1n2nv",
	"QRCodeImage": "_1FMFN",
	"Phone": "ULvOJ",
	"CopyRight": "_3FnQS"
};
module.exports = exports;
