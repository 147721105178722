// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n._3Elpl {\n  display: flex;\n  height: 60px;\n  max-width: 1080px;\n  margin: 0 auto;\n}\n.cieiA {\n  margin-bottom: 0;\n  font-size: 18px;\n  display: flex;\n  align-items: center;\n  color: #2A8AF9;\n  flex: 1 1;\n}\n@media (max-width: 600px) {\n  .cieiA {\n    justify-content: center;\n    padding-left: 60px;\n  }\n}\n.cieiA img {\n  width: 36px;\n  margin-right: 10px;\n}\n.WGGVi {\n  width: 60px;\n  display: none;\n  align-items: center;\n  justify-content: center;\n}\n@media (max-width: 600px) {\n  .WGGVi {\n    display: flex;\n  }\n}\n.WGGVi path {\n  stroke: #2A8AF9;\n}\n._3MvG2 {\n  display: flex;\n  align-items: center;\n}\n@media (max-width: 600px) {\n  ._3MvG2 {\n    display: none;\n  }\n}\n._3MvG2 a {\n  color: black;\n  margin: 0 20px;\n}\n.nKvXL {\n  font-size: 20px;\n}\n.nKvXL .ant-drawer-body {\n  background: #2A8AF9;\n}\n.nKvXL ._112Ks {\n  padding-top: 80px;\n}\n.nKvXL ._112Ks a {\n  display: block;\n  color: white;\n  border-bottom: 1px solid white;\n  padding: 10px 0;\n}\n.nKvXL ._112Ks a:first-child {\n  border-top: 1px solid white;\n}\n._1cTc_ {\n  display: flex;\n  align-items: center;\n}\n@media (max-width: 600px) {\n  ._1cTc_ {\n    color: white;\n    font-weight: lighter;\n  }\n  ._1cTc_ path {\n    stroke: white;\n  }\n}\n@media (min-width: 600px) {\n  ._1cTc_ {\n    margin-left: 20px;\n  }\n}\n._1cTc_ ._1g_4C {\n  padding: 10px 2px;\n  cursor: pointer;\n}\n._1cTc_ ._1g_4C:first-child {\n  padding-left: 0;\n}\n._1cTc_ ._1g_4C._1-vt- {\n  font-weight: bolder;\n  cursor: default;\n}\n@media (min-width: 600px) {\n  ._1cTc_ ._1g_4C._1-vt- {\n    color: #2A8AF9;\n  }\n}\n._1cTc_ img {\n  margin-left: 6px;\n}\n", ""]);
// Exports
exports.locals = {
	"Root": "_3Elpl",
	"Logo": "cieiA",
	"MobileMenuTrigger": "WGGVi",
	"DesktopMenu": "_3MvG2",
	"MobileMenu": "nKvXL",
	"Features": "_112Ks",
	"Languages": "_1cTc_",
	"Language": "_1g_4C",
	"active": "_1-vt-"
};
module.exports = exports;
