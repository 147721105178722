import { setFeatures } from 'relient/features';

export const HOME = 'HOME';
export const OWNERS = 'OWNERS';
export const RESIDENTS = 'RESIDENTS';
export const ABOUT_US = 'ABOUT_US';
export const CONTACT_US = 'CONTACT_US';

export const features = [{
  key: HOME,
  link: '/',
  text: 'home',
}, {
  key: OWNERS,
  externalLink: 'https://wjl.appfolio.com/oportal/users/log_in',
  text: 'ownersEntry',
}, {
  key: RESIDENTS,
  externalLink: 'https://wjl.appfolio.com/connect/users/sign_in',
  text: 'residentsEntry',
}, {
  key: ABOUT_US,
  text: 'aboutUs',
}, {
  key: CONTACT_US,
  text: 'contactUs',
}];

setFeatures(features);
