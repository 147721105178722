import React from 'react';
import { number, string } from 'prop-types';

const result = ({ className, size = 24, color = 'black' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.92 8.5h.87l-.438-.751a8.53 8.53 0 00-4.6-3.782l-1.21-.414.608 1.125a15.147 15.147 0 011.336 3.446l.096.376h3.338zm-6.509-4.744L12 3.16l-.411.595a14.586 14.586 0 00-1.978 4.1l-.193.644h5.164l-.193-.644a14.586 14.586 0 00-1.978-4.1zM3.775 14.12l.095.379h4.334l-.068-.56A16.025 16.025 0 018 12c0-.651.057-1.289.136-1.94l.068-.56H3.87l-.095.379A8.74 8.74 0 003.5 12c0 .738.107 1.45.275 2.121zM5.08 15.5h-.867l.434.75a8.486 8.486 0 004.601 3.783l1.21.414-.608-1.125a15.15 15.15 0 01-1.336-3.446l-.096-.376H5.08zm2.95-7h.388l.096-.376A15.15 15.15 0 019.85 4.678l.608-1.125-1.21.414a8.487 8.487 0 00-4.6 3.783l-.435.75H8.03zm3.559 11.744l.411.595.411-.595a14.585 14.585 0 001.978-4.1l.194-.644H9.418l.193.644a14.586 14.586 0 001.978 4.1zM14.34 14.5h.436l.06-.432c.09-.669.164-1.355.164-2.068 0-.713-.073-1.409-.165-2.069l-.06-.431H9.224l-.06.431C9.075 10.591 9 11.287 9 12c0 .713.073 1.4.165 2.068l.059.432h5.116zm-.19 4.822l-.608 1.125 1.21-.414a8.53 8.53 0 004.6-3.782l.437-.751h-4.207l-.096.376a15.147 15.147 0 01-1.336 3.446zm1.714-5.382l-.068.56h4.334l.095-.379A8.743 8.743 0 0020.5 12c0-.738-.107-1.45-.275-2.121L20.13 9.5h-4.334l.068.56c.079.651.136 1.289.136 1.94s-.057 1.289-.136 1.94zM2.5 12c0-5.245 4.247-9.5 9.49-9.5 5.254 0 9.51 4.256 9.51 9.5s-4.256 9.5-9.51 9.5c-5.243 0-9.49-4.256-9.49-9.5z"
      stroke={color}
    />
  </svg>
);

result.propTypes = {
  className: string,
  size: number,
  color: string,
};

result.displayName = __filename;

export default result;
