// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.gXX8e {\n  background: #F8F8F8;\n}\n@media (min-width: 600px) {\n  .gXX8e {\n    padding-bottom: 160px;\n  }\n}\n@media (max-width: 600px) {\n  .gXX8e {\n    padding-bottom: 48px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Content": "gXX8e"
};
module.exports = exports;
